/* Loader.css */

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  height: 35vh;
  }
  
  .reverse-spinner {
    position: relative;
    height: 120px;
    width: 120px;
    border: 4px solid transparent;
    border-top-color: #00DBAE;
    border-left-color: #00DBAE;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .reverse-spinner::before {
    position: absolute;
    top: 10px;  /* Adjust to create gap */
    left: 10px;  /* Adjust to create gap */
    right: 10px;  /* Adjust to create gap */
    bottom: 10px;  /* Adjust to create gap */
    content: "";
    border: 4px solid transparent;
    border-top-color: #00DBAE;
    border-left-color: #00DBAE;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
  
  .loader-text {
    position: absolute;
    font-size: 16px;
    color: #00DBAE;
    text-align: center;
    font-weight: 800;
    z-index: 10;  /* Added background to make gap visible */
    padding: 5px 10px;  /* Adjusted padding */
    border-radius: 10px;  /* Optional: makes text background rounded */
  }
  
  @-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @-webkit-keyframes spinBack {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }
  
  @keyframes spinBack {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }
  


 